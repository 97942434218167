import React from "react";
import { FaInstagram, FaPhone, FaEnvelope } from "react-icons/fa";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact-container">
        <h2 className="contact-title">Reach out</h2>
        <div className="contact-content">
          <div className="contact-text" data-aos="fade-left">
            <p className="contact-statement">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Excepturi praesentium numquam dolorem esse non delectus distinctio
              laborum blanditiis minus qui magni voluptates officiis veritatis,
              cupiditate nam id nemo totam neque.
            </p>

            <div className="social-links">
              <div className="socials">
                <a href="mailto:marthamwau16@gmail.com" className="social-link">
                  <FaEnvelope className="social-icon" />
                  <span>mwau.kaboi@gmail.com</span>
                </a>
              </div>
              <div className="socials">
                <FaPhone className="social-icon" />
                <span>+254-712-345-6789</span>
              </div>
              <div className="socials">
                <a
                  href="https://github.com/MarsMwau"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaInstagram className="social-icon" />
                  <span>Instagram</span>
                </a>
              </div>
            </div>
          </div>
          <div className="img-contact" data-aos="fade-right">
            <img
              src="https://i.pinimg.com/736x/72/ab/3f/72ab3fbf23c7114f3f781eeb61a9238a.jpg"
              alt="Love"
              className="contact-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import "./Navbar.css";

const navItems = [
  { title: "Home", path: "home" },
  { title: "About", path: "about" },
  { title: "Gallery", path: "gallery" },
  { title: "Events", path: "events" },
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleContactClick = () => {
    setMenuOpen(false);
  };

  return (
    <section className="nav">
      <div className="navbar">
        <div className="navbar-content">
          <div className="logo">
            <ScrollLink
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <h1>
                <span>
                  B<span>M</span>K
                </span>{" "}
                Foundation
              </h1>
            </ScrollLink>
          </div>
          <div className="nav-menu">
            <div className="menu-toggle" onClick={toggleMenu}>
              {menuOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div className={`menu-list ${menuOpen ? "open" : ""}`}>
              {navItems.map((item, index) => (
                <ScrollLink
                  key={index}
                  className="menu-item"
                  to={item.path}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggleMenu}
                  activeClass="active"
                >
                  {item.title}
                </ScrollLink>
              ))}
              <button className="menu-item" onClick={handleContactClick}>
                <ScrollLink
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggleMenu}
                  activeClass="active"
                >
                  Contact
                </ScrollLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;

import React from "react";
import { Link } from "react-scroll";
import "./Footer.css";

const Footer = () => {
  const navItems = [
    { title: "Home", path: "home" },
    { title: "About", path: "about" },
    { title: "Gallery", path: "gallery" },
    { title: "Events", path: "events" },
    { title: "Contact", path: "contact" },
  ];

  const top = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="footer">
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="footer-logo">
                B<span>M</span>K Foundation
              </h3>
              <p className="footer-statement">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus
                cum vitae non, quasi adipisci doloremque sequi ea, corporis
                atque assumenda blanditiis ipsa deleniti inventore ex, facere
                qui dolor iste suscipit!
              </p>
            </div>
            <div className="col">
              <h4>Navigation</h4>
              <ui className="list-unstyled">
                {navItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ui>
            </div>
            <div className="col">
              <h4>Reach us</h4>
              <li>bmk.foundation@gmail.com</li>
              <li>342-420-6969</li>
              <li>Machakos, Kenya</li>
            </div>
            <div className="col">
              <button class="footer-button" onClick={top}>
                <svg class="svgIcon" viewBox="0 0 384 512">
                  <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
                </svg>
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <p className="col-sm">
              &copy;{new Date().getFullYear()} created with{" "}
              <span role="img" aria-label="heart" className="pulse">
                {" "}
                ❤️{" "}
              </span>{" "}
              by{" "}
              <a href="https://martha-mumbua-portfolio.netlify.app/">
                Martha Mwau
              </a>{" "}
              | All rights reserved | Terms Of Service | Privacy
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React from "react";
import "./Quote.css";
import { QUOTES } from "../../Utils/data";

const Quote = () => {
  const getDailyQuote = () => {
    const today = new Date().getDate();
    const randomIndex = today % QUOTES.length;
    return QUOTES[randomIndex];
  };

  const dailyQuote = getDailyQuote();

  return (
    <div className="quotes">
      <div className="quotes-container">
        <div className="quotes-content">
          <p>"{dailyQuote.quote}"</p>
          <h3>- {dailyQuote.author}</h3>
        </div>
      </div>
    </div>
  );
};

export default Quote;

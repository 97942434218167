import React from "react";
import "./Home.css";
import { Link } from "react-scroll";

function Home() {
  return (
    <section className="home" id="home">
      <div className="intro">
        <div className="intro-content">
          <div className="intro-text">
            <h3 className="greet">' Mbukilye, ngukilye'</h3>
            <h2 className="name">
              Give a <span>helping</span> hand to those who need it!
            </h2>
            <p className="soft">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed,
              quos esse explicabo qui deleniti odio sequi vitae eveniet hic
              nesciunt ipsam delectus quisquam excepturi repellat molestias
              obcaecati officia? Voluptates, maiores!
            </p>
            <div className="buttons">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button className="btn1">Know About us</button>
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button className="btn2">Reach out</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
import React from "react";
import { eventsImg } from "../../Utils/data";
import "./Events.css";

const Events = () => {


  return (
    <div className="events">
      <div className="events-container">
        <h2 className="events-title">Upcoming Events</h2>
        <div className="events-content">
          <div className="events-text">
            <h3>The biggest game</h3>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum
              facere, officia consequuntur, eius quae aperiam dolorum iure
              corporis in accusamus ratione autem, deleniti iste nobis corrupti.
              Maxime eius corrupti sapiente.
            </p>
            <h4>Date</h4>
            
          </div>
          <div className="slider-images">
              {eventsImg.map((event, index) => (
                <div key={index} className="event-card">
                  <img src={event.image} alt="img" className="event-img" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

import React from "react";
import "./AboutInfo.css";

const AboutInfo = () => {
  return (
    <div className="section-about-info">
      <div className="about-info-container">
        <h2>About Us</h2>
        <div className="about-boxes">
          <div className="about-top-boxes">
            <div className="box1">
              <div className="box-text">
                <h3>Who are we?</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestias, provident, nemo vero cumque quos consequuntur
                  facere error tenetur illo iure delectus? Laudantium fuga ut
                  repellat nobis, facilis sunt molestias blanditiis?
                </p>
              </div>
            </div>
            <div className="box2">
              <div className="box-text">
                <h3>What do we do?</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Deleniti, autem, suscipit corrupti voluptatem obcaecati
                  doloremque voluptatibus eum voluptates dolore minus ad iste
                  nulla provident officia vitae maiores, placeat soluta cum.
                </p>
              </div>
            </div>
          </div>
          <div className="about-bottom-boxes">
            <div className="box3">
              <div className="box-text">
                <h3>Our Mission</h3>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Reprehenderit aut, facilis minus aliquid beatae, expedita
                  dolore dicta eaque adipisci sequi rem magni incidunt? Eveniet,
                  facere non quaerat fugiat animi laborum.
                </p>
              </div>
            </div>
            <div className="box4">
              <div className="box-text">
                <h3>Our Vision</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Explicabo error eum temporibus sequi, quo ipsa incidunt, nisi
                  maiores libero, similique recusandae laudantium? Maxime
                  consectetur illum ipsam in tempora. Optio, nulla!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfo;
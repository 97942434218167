import React from "react";
import "./About.css";
import Dad2 from "../../assets/Dad2.jpg";
import AboutCard from "./AboutCard/AboutCard";
import AboutInfo from "./AboutInfo/AboutInfo";

const About = () => {
  return (
    <section className="about">
      <div className="about-container">
        <h2 className="about-title">Who is BMK?</h2>
        <div className="about-content">
          <div className="about-text" data-aos="fade-left">
            <h3 className="about-intro">
              Boniface <span>Mwau</span> Kaboi
            </h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
              repudiandae iste rerum ut modi pariatur enim, reiciendis
              praesentium inventore facere quisquam ratione! Dolore, minus iure
              odit pariatur tempora consectetur. Officia.
            </p>
          </div>
          <div className="img-content" data-aos="fade-right">
            <img src={Dad2} alt="BMK" className="dad2" />
          </div>
        </div>
        <AboutCard />
        <AboutInfo />
      </div>
    </section>
  );
};

export default About;

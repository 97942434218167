import React, { useState } from "react";
import "./Gallery.css";
import { GalleryImg } from "../../Utils/data";
import CloseIcon from '@mui/icons-material/Close';

const Gallery = () => {
  const [gallerys] = useState(GalleryImg);
  const [selectedImg, setSelectedImg] = useState(null);
  const [imgOpen, setimgOpen] = useState(false);

  const getImg = (image) => {
    setSelectedImg(image);
    setimgOpen(true);
  };

  return (
    <div className="gallery">
      <div className="gallery-content">
        <h2 className="gallery-title">Gallery</h2>
        <div className={imgOpen? "imgOpen open" : "imgOpen"}>
         <img src={selectedImg} alt="img"/>
         <CloseIcon onClick={() => setimgOpen(false)}  className="close-icon" />
         </div>
        <div className="gallery-container">
          {gallerys.map((gallery, index) => {
            const { image } = gallery;
            return (
              <div
                className="gallery-card"
                key={index}
                onClick={() => getImg(gallery.image)}
              >
                <img
                  src={image}
                  alt="Img"
                  className="g-img"
                  style={{ width: `100%` }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;

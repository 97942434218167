import background from "../assets/background.jpg";
import background2 from "../assets/background2.jpg";
import background3 from "../assets/background3.jpg";
import Dad from "../assets/Dad.jpg";
import Dad2 from "../assets/Dad2.jpg";
import download2 from "../assets/download2.jpeg";
import download3 from "../assets/download3.jpg";
import Bible from "../assets/Bible.jpeg";
import Fairy from "../assets/Fairy.png";
import hisoka from "../assets/hisoka.jpg";
import Lecture from "../assets/Lecture.jpeg";
import Me from "../assets/Me.jpeg";
import Megumi from "../assets/Megumi.jpeg";
import Nanami from "../assets/Nanami.jpeg";
import Nature from "../assets/Nature.jpeg";
import pool from "../assets/pool.jpeg";
import Space from "../assets/Space.jpeg";
import Nanami2 from "../assets/Nanami2.jpeg";
import Dress from "../assets/Dress.jpg";
import game1 from "../assets/game1.jpg";
import game2 from "../assets/game2.jpg";
import game3 from "../assets/game3.jpg";

export const GalleryImg = [
  {
    image: background,
  },
  {
    image: background2,
  },
  {
    image: background3,
  },
  {
    image: Dad,
  },
  {
    image: Dad2,
  },
  {
    image: download2,
  },
  {
    image: download3,
  },
  {
    image: Bible,
  },
  {
    image: Fairy,
  },
  {
    image: hisoka,
  },
  {
    image: Lecture,
  },
  {
    image: Me,
  },
  {
    image: Megumi,
  },
  {
    image: Nanami,
  },
  {
    image: Nature,
  },
  {
    image: pool,
  },
  {
    image: Space,
  },
  {
    image: Nanami2,
  },
  {
    image: Dress,
  },
];

export const eventsImg = [
  {
    image: game3,
  },
  {
    image: game2,
  },
  {
    image: game1,
  },
  {
    image: game1,
  },
];

export const QUOTES = [
  {
    quote: "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    author: "Winston Churchill"
  },
  {
    quote: "Believe you can and you're halfway there.",
    author: "Theodore Roosevelt"
  },
  {
    quote: "The only way to achieve the impossible is to believe it is possible.",
    author: "Charles Kingsleigh"
  },
  {
    quote: "Don't watch the clock; do what it does. Keep going.",
    author: "Sam Levenson"
  },
  {
    quote: "It does not matter how slowly you go as long as you do not stop.",
    author: "Confucius"
  },
  {
    quote: "You are never too old to set another goal or to dream a new dream.",
    author: "C.S. Lewis"
  },
  {
    quote: "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt"
  },
  {
    quote: "Success usually comes to those who are too busy to be looking for it.",
    author: "Henry David Thoreau"
  },
  {
    quote: "The only limit to our realization of tomorrow will be our doubts of today.",
    author: "Franklin D. Roosevelt"
  },
  {
    quote: "You don’t have to be great to start, but you have to start to be great.",
    author: "Zig Ziglar"
  },
  {
    quote: "What you get by achieving your goals is not as important as what you become by achieving your goals.",
    author: "Zig Ziglar"
  },
  {
    quote: "Success is walking from failure to failure with no loss of enthusiasm.",
    author: "Winston Churchill"
  },
  {
    quote: "The best way to predict the future is to create it.",
    author: "Peter Drucker"
  },
  {
    quote: "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    author: "Roy T. Bennett"
  },
  {
    quote: "The only person you are destined to become is the person you decide to be.",
    author: "Ralph Waldo Emerson"
  },
  {
    quote: "You are what you believe yourself to be.",
    author: "Paulo Coelho"
  },
  {
    quote: "The way to get started is to quit talking and begin doing.",
    author: "Walt Disney"
  },
  {
    quote: "Success is not how high you have climbed, but how you make a positive difference to the world.",
    author: "Roy T. Bennett"
  },
  {
    quote: "Don't be afraid to give up the good to go for the great.",
    author: "John D. Rockefeller"
  },
  {
    quote: "The only way to do great work is to love what you do.",
    author: "Steve Jobs"
  },
  {
    quote: "Your time is limited, so don't waste it living someone else's life.",
    author: "Steve Jobs"
  },
  {
    quote: "In the middle of every difficulty lies opportunity.",
    author: "Albert Einstein"
  },
  {
    quote: "The only place where success comes before work is in the dictionary.",
    author: "Vidal Sassoon"
  },
  {
    quote: "The best revenge is massive success.",
    author: "Frank Sinatra"
  },
  {
    quote: "It's not about how bad you want it. It's about how hard you're willing to work for it.",
    author: "Unknown"
  },
];

import React from "react";
import {
  FaHandHoldingHeart,
  FaUserFriends,
  FaHandsHelping,
} from "react-icons/fa";
import "./AboutCard.css";

const AboutCard = () => {
  return (
    <div className="causes">
      <h3 className="causes-title">Our Causes</h3>
      <h2 className="causes-slogan">
        Mbukilye Ngukilye<span> 'A cycle of kindness'</span>
      </h2>
      <div className="about-cont">
        <div className="card">
          <img
            src="https://images.unsplash.com/photo-1605552983816-3b7a9d32b2c6?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="img"
          />
          <div className="card-content">
            <FaHandsHelping className="card-icon" />
            <h1 className="card-title">Helping Hand</h1>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
              eiusmod tempor incididunt ut labore et dolore magna aliqua
            </p>
          </div>
        </div>
        <div className="card">
          <img
            src="https://images.unsplash.com/photo-1444664361762-afba083a4d77?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="img"
          />
          <div className="card-content">
            <FaUserFriends className="card-icon" />
            <h1 className="card-title">Get Involved</h1>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
              eiusmod tempor incididunt ut labore et dolore magna aliqua
            </p>
          </div>
        </div>
        <div className="card">
          <img
            src="https://media.istockphoto.com/id/1145183178/photo/black-hands-holding-red-paper-heart-on-green-background.jpg?s=612x612&w=0&k=20&c=kATp6HXwVvtbBJKCvuWgE9h-893wcTU9wC2TaH-MzyI="
            alt="img"
          />
          <div className="card-content">
            <FaHandHoldingHeart className="card-icon" />
            <h1 className="card-title">Giving Back</h1>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
              eiusmod tempor incididunt ut labore et dolore magna aliqua
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCard;
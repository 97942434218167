import "./App.css";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Events from "./Components/Events/Events";
import Footer from "./Components/Footer/Footer";
import Gallery from "./Components/Gallery/Gallery";
import Home from "./Components/Home/Home";
import Navbar from "./Components/NavBar/Navbar";
import Quote from "./Components/Quotes/Quote";

function App() {
  
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Gallery />
      <Events />
      <Quote />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
